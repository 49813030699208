var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"members-index pb-0"},[_c('Breadcrumbs'),_c('v-row',[_c('v-col',{staticClass:"d-flex pb-0"},[_c('span',{staticClass:"icomoon icon-members d-flex members-icon"}),_c('h1',{staticClass:"d-flex ml-2 page-title text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("members"))+" ")])])],1),_c('Table',{class:("" + (_vm.isAdheraTheme ? 'mt-6' : 'mt-13')),attrs:{"headers":_vm.headers,"items":_vm.membersList.members,"loading":_vm.loading,"item-class":_vm.highlightedRow},on:{"click:row":function($event){_vm.isRedirectToDetailedView ? _vm.routeTo($event,'detailed-view') : _vm.routeTo($event, 'members-profile')}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(_vm.membersList.styles['email'])},[_vm._v(_vm._s(item.email))])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [(item.gender == 1)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("male"))+" ")]):_vm._e(),(item.gender == 2)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("female"))+" ")]):_vm._e()]}},{key:"item.new_data",fn:function(ref){
var item = ref.item;
return [(item.new_data == 0)?_c('span',{staticClass:"text-capitalize pl-3 pr-3"},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")]):_c('v-chip',{staticClass:"font-weight-bold numeric-chip",attrs:{"color":_vm.$store.getters['auth/user']
            ? _vm.$store.getters['auth/user'].theme_config.primary_color
            : ''}},[_vm._v(_vm._s(item.new_data))])]}},{key:"item.relapse.value",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"badge-container",class:{ 'pl-8': 'item.relapse.is_highlighted' }},[(item.relapse.is_highlighted)?_c('v-badge',{staticClass:"d-inline-block",attrs:{"dot":"","color":"#6BC0A5"}}):_vm._e(),_vm._v(" "+_vm._s(item.relapse.relapsedWhenFormatted)+" ")],1)]}}])}),_c('MembersListFilterDialog',{attrs:{"show":_vm.showFiltersDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }